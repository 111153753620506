export default class ProductService {

    getProductsSmall() {
		return fetch('data/products-small.json').then(res => res.json()).then(d => d.data);
	}

	getProducts() {
		return fetch('data/products.json').then(res => res.json()).then(d => d.data);
    }

    getProductsWithOrdersSmall() {
		return fetch('data/products-orders-small.json').then(res => res.json()).then(d => d.data);
	}

	
	getpphproduct() {
		return fetch('data/pphproduct.json').then(res => res.json()).then(d => d.data);
	}

	getpphCategories() {
		return fetch('data/pphCategories.json').then(res => res.json()).then(d => d.data);
	}

	getpphShopByBrand() {
		return fetch('data/pphShopByBrand.json').then(res => res.json()).then(d => d.data);
	}



}

